@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@font-face {
  font-family: "Roboto", sans-serif;
  src: url(../fonts/Roboto/RobotoCondensed-Regular.ttf);
}
body {
  text-transform: uppercase !important;
}
/* @font-face {
  font-family: 'Poppins', sans-serif;
  src: url("../fonts/Douglas-Calgury-Block.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url("../fonts/Douglas-Montreal-Rounded.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
button {
  outline: none !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

/* table */
.header-class {
  border: none !important;
  background: #2b322b !important;
}
.react-bootstrap-table {
  overflow-x: auto;
}

.table thead th {
  border: none !important;
  font-size: 16px;
  color: #ffffff;
}
.table tr:nth-child(odd) {
  background: #f9fafe;
  font-size: 14px;
  color: #9a999b;
  border: none !important;
}
.table tr:nth-child(even) {
  background: transparent;
  font-size: 14px;
  color: #9a999b;
  border: none !important;
}

/* alert and loading */
._loading_overlay_wrapper {
  height: 100vh;
}
._loading_overlay_wrapper > ._loading_overlay_overlay {
  position: fixed !important;
  z-index: 9999999999;
}
._loading_overlay_spinner svg {
  position: fixed !important;
  width: 4% !important;
}
._loading_overlay_spinner svg circle {
  stroke: red !important;
}

#__react-alert__ div div div {
  text-transform: capitalize !important;
  width: 100% !important;
  font-weight: 400 !important;
  /* z-index: 9999999 !important; */
  margin-top: 75px !important;
}

#__react-alert__ div {
  z-index: 999999999 !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  font-weight: 400;
}

.btn-pre-next {
  padding: 8px 30px;
  background-color: transparent;
  border: 1px solid #6ee0dd;
  color: #6ee0de;
  border-radius: 4px;
}
.select_clr {
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px 0px 0px;
}
.print {
  font-size: 29px;
}
.re-arange-parent-div {
  display: flex;
  flex-wrap: wrap;
}
.re-arange-child-1 {
  flex-basis: 79%;
}
.re-arange-child-2 {
  flex-basis: 27%;
  display: flex;
  align-items: center;
}
.re-arange-pitching-child-1 {
  flex-basis: 68%;
}
.rearange-btn {
  border: none;
  background: transparent;
}
.p_description {
  text-align: center;
  margin-bottom: 9px;
  font-size: 16px;
}
.p_description-1 {
  margin-bottom: 9px;
  font-size: 16px;
}
.span_p_description {
  margin: 0px 15px;
}
.input_rearange_div {
  margin: 15px 0px;
  display: flex;
  justify-content: center;
}
.abc {
  padding-left: 21px;
}
.main-head-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rearange_zone_label {
  padding-top: 5px;
  margin: 0px 20px;
  color: #95a1bb;
  width: 100px;
}
.rearange_zone_input {
  margin: 0px 20px;
  text-align: center;
  border: 1px solid #9a999b;
  width: 100px;
  padding-top: 3px;
  border-radius: 4px;
}
.rearange_zone_input:focus {
  outline: none !important;
}
.btn-pre-next:focus {
  border: 1px solid #6ee0dd;
  outline: inherit;
  background-color: #f5f5f5;
}
.top_nav {
  z-index: 9 !important;
}
.navbar_width {
  width: 60%;
}
.navbar-nav {
  flex-grow: 1;
}
.navbar-brand {
  padding-right: 3pc;
  margin-right: 0px;
  border-right: 2px solid #9a999b;
}
.nav-item.nav-link {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  color: #2c312c !important;
}
.nav-item.nav-link.active {
  color: #9a999b !important;
}
.btn_login {
  margin: 0px 10px;
}
.login_btn {
  padding-top: 4px;
  height: 36px;
  width: 112px;
  border-radius: 4px;
  outline: none !important;
  border: 1px solid #6ee0de;
  color: #6ee0de;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: center;
  background: transparent;
}
.modal {
  background: rgba(0, 0, 0, 0.9) !important;
}
.modal-backdrop {
  position: unset !important;
}
.modal-dialog {
  background: #fff !important;
}
.modal-title {
  color: #fff !important;
  font-size: 1.5rem !important;
  border: none !important;
  border-radius: 0 !important;
}
.close {
  color: #9a999b !important;
  position: absolute;
  right: 25px !important;
  top: 15px !important;
  z-index: 1;
  outline: none !important;
}
.opacity-animate3 {
  animation: opt-animation3 1s;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.modal-content {
  background: #fff !important;
  border: none !important;
  border-radius: none !important;
  width: 100%;
  height: 100%;
  display: block !important;
}
.modal-body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal_content {
  width: 50%;
}
.hire_us_head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 43px;
  color: #2c312c;
  margin-bottom: 20px;
  text-align: center;
}
.zone_selection_modal_head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 43px;
  color: #2c312c;
  margin-bottom: 20px;
  text-align: center;
}
.prnt_2 {
  font-weight: 500;
}
.slect-demo .prnt_2 {
  margin: 0;
}
.hire_us_head_dlt {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #2c312c;
  margin-bottom: 40px;
  text-align: center;
}
.modal_descripition {
  font-size: 16px;
  line-height: 22px;
  color: #9a999b;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.login_input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #9a999b;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 40px;
  border: 1px solid #9a999b;
  border-radius: 4px;
  outline: none;
  margin-bottom: 15px;
  padding: 12px;
}
.login_input::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input:-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input:-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.div_clr {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.login_input_pswrd {
  font-family: "Poppins", sans-serif;
  width: 100%;
  font-size: 16px;
  height: 40px;
  border: 1px solid #9a999b;
  border-radius: 4px;
  outline: none;
  margin-bottom: 15px;
  padding: 12px;
}
.login_input_pswrd::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input_pswrd::-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input_pswrd:-ms-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.login_input_pswrd:-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #9a999b;
}
.forgot_link {
  font-family: "Poppins", sans-serif;
  position: absolute;
  right: 3%;
  top: 22%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #6ee0de !important;
}
.forgot_link:hover {
  color: #6ee0de;
  text-decoration: none !important;
}
.input_login_btn {
  font-family: "Poppins", sans-serif;
  background: #6ee0de;
  border-radius: 4px;
  color: #fff;
  outline: none !important;
  width: 100%;
  height: 40px;
  border: none;
}
.sgn_up_hed {
  margin: 20px 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #2c312c;
}
.sgn_up_link {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6ee0de;
}
.sgn_up_link:hover {
  color: #6ee0de;
  text-decoration: none !important;
}
.coach-card-left-div iframe {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
@-webkit-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes opt-animation3 {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 530px;
    height: auto;
    border-radius: 5px;
    padding: 20px 0px;
  }
  .modal_content {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    max-width: 730px;
    height: auto;
    border-radius: 5px;
  }
  .modal_dialog_2 {
    max-width: 500px !important;
    height: 300px !important;
  }
}
.signup_btn {
  padding-top: 4px;
  height: 36px;
  width: 112px;
  border-radius: 4px;
  outline: none !important;
  border: none;
  background: #6ee0de;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: center;
}
/* .banner {
  margin-top: 68px;
} */
.banner img {
  width: 100%;
  display: none;
}
.gnerate {
  padding: 0pc 0pc;
}
.generat_heading {
  font-style: normal;
  margin: 0;
  font-size: 32px;
  color: #363940;
  font-weight: 600;
}
.generate-heading-span {
  display: block;
  font-size: 14px;
  cursor: auto;
}
.float-div {
  display: flex;
  justify-content: space-between;
  padding: 0px 35px 0px 20px;
}
.float-div-2 {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 5px;
}
.float-div-2 p {
  margin: 0px;
  font-size: 12px;
  font-weight: 700;
}
.float-left-p {
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
}
.zonebox_parent_div_2 {
  display: flex;
  flex-wrap: wrap;
}
.box_1 {
  margin: 5px;
  width: 125px;
  height: 125px;
  background: #9a999b;
}
.box_2 {
  margin: 5px;
  width: 98px;
  height: 108px;
  background: #9a999b;
}
.box_3 {
  margin: 5px;
  width: 98px;
  height: 108px;
  background: #9a999b;
}
.box_4 {
  margin: 5px;
  width: 98px;
  height: 108px;
  background: #9a999b;
}
.custom-radio {
  position: relative;
}
.custom-radio__control {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 100%;
  border: 2px solid #2c312c;
}
.custom-radio__control:hover + .custom-radio__label:before,
.custom-radio__control:focus + .custom-radio__label:before {
  border-color: #6ee0de;
}
.custom-radio__control:checked + .custom-radio__label:after {
  background: #6ee0de;
  border: 1px solid #6ee0de;
}
.custom-radio__label:before:checked {
  border: 1px solid #6ee0de;
}
.custom-radio__label {
  font-family: "Poppins", sans-serif;
  vertical-align: middle;
  /* display: block; */
  position: relative;
  z-index: 1;
  color: #222;
  padding-left: 27px;
  font-size: 13px;
  line-height: 1.4;
}
.coach-card-input-div {
  padding: 20px 0px;
  margin: 0 auto;
  width: 80%;
  text-align: center;
}
.mrgn {
  padding-left: 5px;
}
.custom-radio__label:before {
  content: " ";
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  background: white;
  border: 1px solid #2c312c;
}
.has-error .custom-radio__label:before {
  border-color: red;
}
ol {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
}

ol > li {
  margin: 0;
  padding: 0px 0 0 5px;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
}

ol > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}
.ul-list {
  font-size: 14px;
}
.ul-list span {
  margin-right: 10px;
  font-weight: 700;
  text-transform: none;
}
.ul-list p {
  margin-left: 66pz;
  margin-bottom: 0px;
  display: flex;
}
.custom-radio__label:after {
  content: " ";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  transition: background 150ms ease-in-out;
}
.ul-list {
  font-size: 14px;
}
.footer {
  padding: 2pc 0pc 1pc 0pc;
  background: rgba(154, 153, 155, 1);
}
.loc_icon {
  width: 12px;
}
.address {
  font-size: 14px;
  color: #fcfbff;
  font-family: "Poppins", sans-serif;
}
.phn_number {
  font-size: 14px;
  color: #fcfbff;
  font-family: "Poppins", sans-serif;
}
.email {
  font-size: 14px;
  color: #fcfbff;
  font-family: "Poppins", sans-serif;
}
.phn_icon {
  width: 14px;
  margin-bottom: 4px;
}
.email_icon {
  width: 20px;
  margin-bottom: 4px;
}
.social_icon {
  padding-left: 30px;
  padding-top: 40px;
}
.icons {
  padding: 0px 10px;
}
.nesw_letter {
  font-size: 14px;
  color: #fcfbff;
  font-family: "Poppins", sans-serif;
  margin-bottom: 25px;
}
.news_letr_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news_letter_input {
  padding: 6px 0px 6px 5px;
  width: 180px;
  height: 46px;
  border: 1px solid #fcfbff;
  outline: none;
  border-radius: 4px;
  background: transparent;
}
.join_btn {
  padding: 9px 0px 16px 0px;
  /* height: 46px; */
  width: 68px;
  border-radius: 4px;
  background: #fcfbff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: center;
  color: #9a999b;
  outline: none !important;
  border: none !important;
}
.news_letter_input::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif; /* Chrome/Opera/Safari */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.news_letter_input::-moz-placeholder {
  font-family: "Poppins", sans-serif; /* Firefox 19+ */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.news_letter_input:-ms-input-placeholder {
  font-family: "Poppins", sans-serif; /* IE 10+ */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.news_letter_input:-moz-placeholder {
  font-family: "Poppins", sans-serif; /* Firefox 18- */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.eng_des {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}
.globe_img {
  width: 16px;
  margin-bottom: 4px;
}
.copy_right_desc {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: right;
}
.modal_center {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.three-x .box {
  border: 1px solid #fff;
  width: 52.9px;
  height: 51px;
  background: #197d3e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.six-x .box {
  border: 1px solid #fff;
  width: 30.2px;
  height: 40px;
  background: #197d3e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.five-x .box {
  border: 1px solid #fff;
  width: 35.2px;
  height: 42px;
  background: #197d3e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.four-x .box {
  border: 1px solid #fff;
  width: 42.3px;
  height: 42px;
  background: #197d3e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color_1 {
  background: #ffff3d !important;
}
.color_2 {
  background: #93ffff !important;
}
.number {
  font-family: "Poppins", sans-serif; /*"Roboto"; */ /* "Poppins", sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}
.wrist_app {
  margin: 15px 0px;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  color: #9a999b;
}
.color_link {
  flex-basis: 17%;
  padding: 6px 0px;
}
.color_img {
  width: 40px;
  /* height: 40px; */
}
span {
  cursor: pointer;
}
.minus,
.plus {
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  padding: 4px 0px 8px 0px;
  border: 1px solid #000;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.number_counter input {
  padding-top: 5px;
  height: 34px;
  border: none;
  outline: none;
  width: 80px;
  font-size: 26px;
  background: transparent;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
h2.select_col {
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: 600;
}
.section_spacing {
  margin: 4pc 0;
}
.number_2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: #363940;
  margin-bottom: -5px;
}
.form-group {
  display: block;
  margin-bottom: 15px;
}
.form-group input {
  padding: 0;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.form-group label {
  position: relative;
  cursor: pointer;
  font-size: 13px;
}
.form-group label:before {
  content: "";
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #6ee0de;
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 4px;
}
.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid #6ee0de;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* .zone_div {
    display: flex;
    flex-wrap: wrap;
  } */
.main_zone_div {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  align-items: center;
}
.zone-child-2-z {
  width: 88%;
  margin-left: 15px;
}

.zone-child-div-1 {
  flex-basis: 40%;
  margin-right: 8px;
}
/* .zone_div_two {
    
  } */
.zone-child-div-2 {
  flex-basis: 58%;
}
.sign_list {
  list-style: none;
  padding: 0px;
  display: flex;
}
.sign_list_zero {
  list-style: none;
  padding: 0px;
}
.list_item_zero {
  font-size: 16px;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.list_item {
  flex-basis: 33%;
  font-size: 16px;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.list_item_2 {
  flex-basis: 33%;
  font-size: 13px;
  color: #95a1bb;
  font-family: "Poppins", sans-serif;
}
.range_div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.range-slider {
  border: 0;
  height: auto;
  margin: 0px 0 0 0%;
  background: #f5f5f5;
  border-radius: 30px;
  padding: 0px;
}
.range-slider {
  width: 80%;
}
.range_div_2 {
  text-align: center;
  width: 20%;
}
.plus_icon_div {
  float: right;
  margin-right: 30px;
  margin-top: 10px;
}
img.plusImg {
  width: 60px;
  cursor: pointer;
}
.box-div-1 {
  display: flex;
  flex-wrap: wrap;
}
.inner_box_1 {
  width: 20px;
  height: 20px;
  background: #9a999b;
  margin: 2px;
}
.inner_box_2 {
  width: 45px;
  height: 20px;
  background: #6ee0de;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner_box_2 p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 5px;
}
.inner_box_row_2 {
  width: 20.5px;
  height: 20px;
  background: #6ee0de;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner_box_row_2 p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  padding-top: 5px;
}
.range-slider__range {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin-bottom: 12px;
}
.range-slider__range::-webkit-slider-thumb {
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: radial-gradient(
    54.37% 54.37% at -2.6% 54.5%,
    #ffffff 0%,
    #f5f5f5 100%
  );
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.value {
  font-size: 14px;
  color: #95a1bb;
  margin: 15px 0px;
}
.lock-btn {
  border: none;
  padding: 0px;
  background: transparent;
  outline: none !important;
}
.zone-child-div-0 {
  flex-basis: 8%;
}
.lock-btn img {
  width: 30px;
}
.select_input {
  height: 50px;
  width: 100px;
  border-radius: 4px;
  border: 1px solid #9a999b;
  font-size: 14px;
  color: #2c312c;
  padding: 13px 4px;
  outline: none;
}
.select_input_download {
  height: 50px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #9a999b;
  font-size: 14px;
  color: #2c312c;
  padding: 13px 4px;
  outline: none;
}
/* .pading {
  padding-left: 0px !important;
  padding-right: 0px !important;
} */
.slecet_input_laber {
  font-size: 20px;
  color: #2c312c;
  font-family: "Poppins", sans-serif;
}
.cross_icon {
  margin: 0px 20px;
  color: #6ee0de;
}
.calculator-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}
.calculator-button button {
  margin-bottom: 15px;
}
.dnld_btn_1 {
  padding-top: 5px;
  /* width: 350px; */
  width: 100%;
  height: 45px;
  background: #6ee0de;
  border-radius: 4px;
  border: none;
  outline: none !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  mix-blend-mode: normal;
}
.grid_prnt_div {
  display: flex;
  flex-wrap: wrap;
}
.three-x .child_div {
  flex-basis: 33.33%;
}
.chikd_wrist_div {
  flex-basis: 26%;
  margin: 0px -1.5px;
}
.grid_prnt_2 {
  display: flex;
  flex-wrap: wrap;
}
.number_p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  margin: 0;
}
span.croos_icon {
  margin-left: 30px;
  margin-right: 5px;
  color: #6ee0de;
}
.four-x .child_div {
  flex-basis: 33.33%;
}
.five-x .child_div {
  flex-basis: 33.3%;
}
.six-x .child_div {
  flex-basis: 33.3%;
}
.color_picker {
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}
.input_div {
  position: relative;
}
.color_img_picker {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 6px;
  cursor: pointer;
  z-index: -1;
}
.color_img_picker_1 {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 6px;
  cursor: pointer;
  z-index: -1;
}
.merge_zonebox_parent_div_2 {
  display: flex;
  flex-wrap: wrap;
}
.merge_box {
  width: 91px;
  height: 110px;
  background: #9a999b;
  margin: 3px;
}
.merge_box_3 {
  width: 157px;
  height: 110px;
  background: #6ee0de;
  margin: 3px;
}
.merge_box_2 {
  width: 320px;
  height: 110px;
  background: #6ee0de;
  margin: 3px;
}
button.minus_btn {
  border: none;
  background: transparent;
  outline: none !important;
}
/* wristband css */
.heek {
  overflow-y: hidden !important;
}
.shuffle_div {
  text-align: center;
}
.banner {
  /* margin-top: 68px; */
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/wrist_bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.signup_btn {
  padding-top: 4px;
  height: 36px;
  width: 112px;
  border-radius: 4px;
  outline: none !important;
  border: none;
  background: #6ee0de;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: center;
}

.wrist_band_btn {
  font-family: "Poppins", sans-serif;
  padding-top: 6px;
  height: 45px;
  width: 180px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  background: #6ee0de;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 20px;
}
.sign_btn {
  font-family: "Poppins", sans-serif;
  padding-top: 6px;
  height: 45px;
  width: 180px;
  border-radius: 5px;
  border: 1px solid #6ee0de;
  outline: none !important;
  background: #fff;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #6ee0de;
  margin-right: 20px;
}
.table_section {
  padding: 2.5pc 0pc;
}
.mrgn {
  margin: 30px 0px;
}
.heading_wrist {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #2c312c;
  margin: 0;
}
.icon_td {
  display: flex;
}
.thead_cls {
  background: #2b322b;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  color: #ffffff;
}
.row__one {
  background: #f9fafe;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9a999b;
}
.row__two {
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9a999b;
}
.icons_link {
  width: 30px;
  color: transparent;
  cursor: pointer;
}
.icons_link:hover {
  text-decoration: none;
}
.icon_img {
  width: 30px;
}
.prnt_head_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* wrist signs */
.signup_btn {
  padding-top: 4px;
  height: 36px;
  width: 112px;
  border-radius: 4px;
  outline: none !important;
  border: none;
  background: #6ee0de;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: center;
}
.wrist_band_btn {
  font-family: "Poppins", sans-serif;
  padding-top: 6px;
  height: 45px;
  width: 180px;
  border-radius: 5px;
  border: none;
  outline: none !important;
  background: #6ee0de;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 20px;
}
/* .sign_btn {
  font-family: 'Douglas-Calgury Block';
  padding-top: 6px;
  height: 45px;
  width: 180px;
  border-radius: 5px;
  border: 1px solid #6EE0DE;
  outline: none !important;
  background: #fff;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #6EE0DE;
  margin-right: 20px;
} */
.table_section {
  padding: 2.5pc 0pc;
}
/* .heading_wrist {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  color: #2c312c;
} */
.btn_list {
  height: 40px;
  width: 208px;
  border-radius: 4px;
  background: #6ee0de;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none !important;
  margin: 0px 15px 0px 0px;
}
.btn_save_div button {
  font-family: "Poppins", sans-serif;
  background: #6ee0de;
  border-radius: 4px;
  color: #fff;
  outline: none;
  width: 210px;
  height: 40px;
  border: none;
}
.frColr {
  z-index: 9999999;
  position: absolute;
}
.frz {
  z-index: 0 !important;
}
.thead_cls {
  background: #2b322b;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  color: #ffffff;
}
.row__one {
  background: #f9fafe;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9a999b;
}
.row__two {
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9a999b;
}
.icons_link {
  height: 30px;
  width: 30px;
  color: transparent;
}
.icons_link:hover {
  text-decoration: none;
}
/* .icon_img {
  height: 30px;
  width: 30px;
} */
.clr_div {
  display: flex;
  flex-wrap: wrap;
}

.color_picker {
  margin: 6px 0px;
  width: 40px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}
.input_div {
  position: relative;
}
.color_img_picker {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 6px;
  cursor: pointer;
  z-index: 1;
}
.instruction-head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 600;
}
.instruction-list {
  padding-left: 16px;
  list-style: auto;
}
.instruction-list li {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.instruction-list ul {
  margin-left: 15px;
  padding: 0px;
  list-style: lower-alpha;
}
.color_link {
  flex-basis: 14%;
  padding: 6px 0px;
  cursor: pointer;
}
.btn_save_div {
  margin-top: 20px;
  text-align: center;
}
.modal_content_1 {
  width: 100%;
}
.color_div_tb {
  width: 138px;
  height: 28px;
  background: #ff0000;
  border-radius: 4px;
}
.color_div_tb_1 {
  width: 138px;
  height: 28px;
  background: #000000;
  border-radius: 4px;
}
.color_div_tb_2 {
  width: 138px;
  height: 28px;
  background: #ff6f00;
  border-radius: 4px;
}
.color_div_tb_3 {
  width: 138px;
  height: 28px;
  background: #1aff00;

  border-radius: 4px;
}
.color_div_tb_4 {
  width: 138px;
  height: 28px;
  background: #0044ff;

  border-radius: 4px;
}
.color_div_tb_5 {
  width: 138px;
  height: 28px;
  background: #ac2d2d;

  border-radius: 4px;
}
.color_div_tb_6 {
  width: 138px;
  height: 28px;
  background: #5400ff;

  border-radius: 4px;
}
.icons_link {
  color: #000 !important;
}
.icons_link:hover {
  color: #000;
}
.view_name_head {
  color: #000;
}
.label_cls {
  color: #000;
  font-weight: 600;
}
.selected_clr_head,
.select_clr_head {
  font-weight: 600;
}
.selectColors {
  color: #000;
}
.selected_clr {
  color: #000;
}
.View_name_selected {
  width: 100;
  height: 40px;
  border: 1px solid #9a999b;
  padding: 10px 5px;
  margin-bottom: 15px;
}
.color_img_div {
  margin-bottom: 12px;
}
.coach-card-main-div {
  display: flex;
  flex-wrap: wrap;
}
.coach-card-left-div {
  flex-basis: 65%;
}
.coach-card-right-div {
  flex-basis: 35%;
}
.h_cls {
  text-align: center;
}
/* modals */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.ReactModal__Content[aria-label="ZoneNameModal"] {
  padding: 20px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="OffensiveModal"] {
  padding: 40px 80px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="coach-card-pitching"] {
  padding: 40px 80px !important;
  width: 85%;
}
.ReactModal__Content[aria-label="looseModal"] {
  padding: 40px 80px !important;
  width: 710px;
}
.ReactModal__Content[aria-label="coach-card-modal"] {
  padding: 40px 30px !important;
  /* width: 530px;  ------ for coach card preview of V1 card */
  width: 575px;
}
.ReactModal__Content[aria-label="deffensiveModal"] {
  padding: 40px 80px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="viewsignsModal"] {
  padding: 20px 20px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="pitchingModal"] {
  padding: 40px 80px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="viewModal"] {
  padding: 40px 80px !important;
  width: 530px;
}
.ReactModal__Content[aria-label="editshufflModal"] {
  padding: 40px 80px !important;
  width: 530px;
}
.select_col span {
  font-size: 14px;
  cursor: auto;
}
.close {
  color: #9a999b !important;
  position: absolute;
  right: 25px !important;
  top: 15px !important;
  z-index: 1;
  outline: none !important;
}
.modal_content {
  width: 100% !important;
}
.input_login_btn_mrgn {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  background: #6ee0de;
  border-radius: 4px;
  color: #fff;
  outline: none !important;
  width: 200px;
  height: 40px;
  border: none;
}
.input_login_btn_mrgn:disabled,
.disable_Class:disabled {
  background: #9a999b;
  cursor: not-allowed;
}
.Zone_Container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  font-size: 25px;
}
.PopulatedZone_Container {
  border: 2px solid #197d3e;
}
.SelectedZone_Container {
  border: 4px solid #197d3e;
}
@media (max-width: 1201px) {
  /* .grid_prnt_div {
            flex-direction: column;
            align-items: center;
        } */
  .three-x .child_div {
    flex-basis: 20%;
    margin: 0px 0px;
  }
  .chikd_wrist_div {
    flex-basis: 20%;
    margin: 0px 0px;
  }
  .four-x .child_div {
    flex-basis: 26%;
    margin: 0px -1px;
  }
  .five-x .child_div {
    flex-basis: 26%;
    margin: 0px -1.5px;
  }
  .six-x .child_div {
    flex-basis: 26%;
    margin: 0px;
  }
  .number {
    font-size: 16px;
  }
  .three-x .box,
  .box {
    width: 25px;
    height: 35px;
  }
  .four-x .box {
    width: 25px;
    height: 35px;
  }
  .five-x .box {
    width: 25px;
    height: 35px;
  }
  .six-x .box {
    width: 25px;
    height: 35px;
  }
}
@media (max-width: 1199px) {
  /* .grid_prnt_div {
            flex-direction: column;
            align-items: center;
        } */
  .box_1 {
    width: 102px;
    height: 125px;
  }
  .six-x .box {
    width: 22px;
    height: 32px;
  }
  .three-x .child_div {
    flex-basis: 24%;
    margin: 0px 0px;
  }
  .chikd_wrist_div {
    flex-basis: 24%;
    margin: 0px 0px;
  }
  .four-x .child_div {
    flex-basis: 30%;
    margin: 0px 0px;
  }
  .five-x .child_div {
    flex-basis: 31%;
    margin: 0px -1px;
  }
  .six-x .child_div {
    flex-basis: 33%;
    margin: 0px -3px;
  }
  .number {
    font-size: 16px;
  }
  .three-x .box,
  .box {
    width: 25px;
    height: 35px;
  }
  .four-x .box {
    width: 25px;
    height: 35px;
  }
  .five-x .box {
    width: 25px;
    height: 35px;
  }
  .box_wrsit_3 {
    width: 25px;
    height: 35px;
  }
  .box_2 {
    margin: 4px;
    width: 82px;
    height: 92px;
  }
  .box_3 {
    margin: 3px;
    width: 84px;
    height: 94px;
  }
  .box_4 {
    margin: 5px;
    width: 80px;
    height: 90px;
  }
}
@media (max-width: 992px) {
  .four-x .child_div {
    flex-basis: 33%;
    margin: 0px -1px;
  }
  .five-x .child_div {
    flex-basis: 33%;
    margin: 0px -1px;
  }
  .six-x .child_div {
    flex-basis: 33%;
    margin: 0px -1px;
  }
  .five-x .box {
    width: 19px;
    height: 29px;
  }
  .six-x .box {
    width: 16px;
    height: 27px;
  }
  .box_1 {
    width: 72px;
    height: 83px;
  }
  .box_2 {
    margin: 2px;
    width: 62px;
    height: 72px;
  }
  .box_3 {
    margin: 3px;
    width: 60px;
    height: 70px;
  }
  .navbar_width {
    width: 100%;
  }
  .generat_heading {
    text-align: center;
  }

  /* .cls_center {
            display: flex;
            align-items: center;
            flex-direction: column;
        } */
  .three-x .child_div {
    flex-basis: 28%;
    margin: 0px 0px;
  }
  .chikd_wrist_div {
    flex-basis: 28%;
    margin: 0px 0px;
  }
  .form-group label:before {
    display: block;
  }
  .list_item {
    font-size: 12px;
  }
  .value {
    font-size: 14px;
  }
  .list_item_zero {
    font-size: 12px;
  }
  label.mrgn {
    margin-top: 27px;
  }
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 18px;
  }
  .box_4 {
    margin: 3px;
    width: 60px;
    height: 70px;
  }
  .four-x .box {
    width: 23px;
    height: 33px;
  }
  .chikd_wrist_div {
    flex-basis: 33%;
    margin: 0px 0px;
  }
}
@media (max-width: 768px) {
  .hire_us_head_dlt {
    margin-top: 40px;
  }
  .order_div {
    order: 1;
  }
  .order_div_2 {
    order: 0;
  }
  .box_1 {
    width: 100px;
    height: 105px;
  }
  .box_2 {
    margin: 3px;
    width: 96px;
    height: 106px;
  }
  .box_3 {
    margin: 3px;
    width: 96px;
    height: 106px;
  }
  .fr_order {
    order: 1;
  }
  .fr_order_1 {
    padding-top: 20px;
    padding-bottom: 20px;
    order: 0;
  }
  .form-group label:before {
    display: inline-block;
  }
  .list_item_zero {
    font-size: 16px;
  }
  .list_item {
    font-size: 16px;
  }
  .three-x .child_div {
    flex-basis: 22%;
    margin: 0px -2px;
  }
  .chikd_wrist_div {
    flex-basis: 22%;
    margin: 0px -2px;
  }
  .grid_prnt_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .box_4 {
    margin: 3px;
    width: 96px;
    height: 106px;
  }
  .four-x .box {
    width: 31px;
    height: 41px;
  }
  .five-x .child_div {
    flex-basis: 33%;
    margin: 0px -3px;
  }
  .five-x .box {
    width: 25px;
    height: 29px;
  }
  .six-x .child_div {
    flex-basis: 33%;
    margin: 0px 0px;
  }
  .six-x .box {
    width: 22px;
    height: 27px;
  }
}
@media (max-width: 324px) {
  .six-x .box {
    width: 38px !important;
    height: 38px !important;
  }
}

@media (max-width: 577px) {
  .zone_name_circle {
    flex-basis: 25% !important;
  }
  .ReactModal__Content[aria-label="coach-card-pitching"] {
    padding: 40px 20px !important;
    width: 90%;
  }
  .btn_list_responsive {
    margin-right: 20px !important;
  }
  .head_div {
    margin: 10px 0px;
  }
  .main-head-div {
    justify-content: center;
  }
  .instruction-2 {
    padding: 0px 15px;
  }
  .zone-child-2-z {
    width: 100%;
    margin-left: 0px;
  }
  .instruction-list {
    padding-left: 15px;
  }
  .zone-child-div-0 {
    flex-basis: 100%;
    text-align: right;
    margin-bottom: 15px;
  }
  .div-center {
    text-align: center;
  }
  .banner_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  .main_zone_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
    border: 2px solid #6ee0dd;
    padding: 15px;
    border-radius: 5px;
  }
  .listing_btn_div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .zone-child-div-1 {
    flex-basis: 69%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .zone-child-div-1 > div {
    width: 100%;
  }
  .zone-child-div-2 {
    flex-basis: 100%;
    padding-top: 15px;
  }
  .phn_number {
    margin: 0;
  }
  .social_icon {
    padding-left: 0;
    padding-top: 0px;
    padding-bottom: 13px;
  }
  .news_letr_div {
    justify-content: left;
  }
  .news_letr_div input {
    margin-right: 10px;
  }
  img.color_img {
    width: 60%;
  }
  .color_img_picker {
    width: 27px;
    height: 27px;
  }
  .thead_cls th {
    font-weight: normal;
  }
  .prnt_head_div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .heading_wrist {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: #2c312c;
  }
  .btn_list {
    margin-right: 0px;
    width: 250px;
    margin-bottom: 10px;
    line-height: 17px;
    padding-top: 0px;
  }
  .h_cls {
    font-size: 14px;
  }
  .banner {
    text-align: center;
  }
  .icon_td {
    display: flex;
  }
  .wrist_band_btn {
    margin-bottom: 10px;
  }
  .sign_btn {
    margin-bottom: 10px;
    margin-right: 0px;
  }
  .input_login_btn_mrgn {
    width: 220px;
  }
  .phn_number {
    margin: 0;
  }
  .hire_us_head {
    margin-top: 50px;
    font-size: 20px;
    line-height: 25px;
  }
  .social_icon {
    padding-left: 0;
    padding-top: 0px;
    padding-bottom: 13px;
  }
  .thead_cls th {
    min-width: 115px;
  }
  .news_letr_div {
    justify-content: left;
  }
  .news_letr_div input {
    margin-right: 10px;
  }
  img.color_img {
    width: 60%;
  }
  .color_img_picker {
    width: 27px;
    height: 27px;
  }
  .thead_cls th {
    font-weight: normal;
  }
  .prnt_head_div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .heading_wrist {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #2c312c;
  }
  .banner {
    text-align: center;
  }
  .icon_td {
    display: flex;
  }
  .wrist_band_btn {
    margin-bottom: 10px;
  }
  .sign_btn {
    margin-bottom: 10px;
  }
  .select_input {
    width: 78px;
  }
  .select_input_download {
    width: 86%;
  }
  .slecet_input_laber {
    font-size: 16px;
    margin-left: 6px;
  }
  .cross_icon {
    margin: 0px 17px 0px 17px;
  }
  .phn_number {
    margin: 0;
  }
  .social_icon {
    padding-left: 0;
    padding-top: 0px;
    padding-bottom: 13px;
  }
  .news_letr_div {
    justify-content: left;
    text-align: left;
  }
  .news_letter_input {
    margin-right: 10px;
  }
  img.color_img {
    width: 60%;
  }
  .color_img_picker {
    width: 27px;
    height: 27px;
  }
  .color_img_picker_1 {
    width: 32px;
    height: 32px;
  }
  .thead_cls th {
    font-weight: normal;
  }
  .grid_prnt_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .three-x .child_div {
    flex-basis: 21%;
    margin: 3px 0px;
  }
  .chikd_wrist_div {
    flex-basis: 21%;
    margin: 3px 0px;
  }
  .five-x .child_div {
    flex-basis: 33%;
    margin: 3px -1px;
  }
  .six-x .child_div {
    flex-basis: 33%;
    margin: 3px 0px;
  }
  .four-x .child_div {
    flex-basis: 33%;
    margin: 3px -1px;
  }
  .form-group label:before {
    display: inline-block;
    margin-bottom: 5px;
    padding: 7px;
    width: auto;
    height: auto;
  }
  .form-group input:checked + label:after {
    top: 3px;
    left: 6px;
    width: 6px;
    height: 11px;
  }
  .zonebox_parent_div_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .box_2 {
    margin: 3px;
    width: 75px;
    height: 85px;
  }
  .box_3 {
    margin: 3px;
    width: 80px;
    height: 90px;
  }
  .box_4 {
    margin: 3px;
    width: 80px;
    height: 90px;
  }
  label.mrgn {
    margin-top: 0px;
  }
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    margin-left: 0px;
  }
  .plus_icon_div {
    padding: 15px 0px;
  }
  img.plusImg {
    width: 40px;
    cursor: pointer;
  }
  .range-slider__range {
    margin-bottom: 18px;
  }
  .three-x .box,
  .box {
    width: 70px;
    height: 70px;
  }
  .five-x .box {
    width: 45px;
    height: 45px;
  }
  .six-x .box {
    width: 40px;
    height: 43px;
  }
  .four-x .box {
    width: 50px;
    height: 50px;
  }
  .number_counter input {
    padding-top: 5px;
    height: 34px;
    border: none;
    outline: none;
    width: 80px;
    font-size: 26px;
    background: transparent;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
  .minus,
  .plus {
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    padding: 2px 0px 8px 0px;
    border: 1px solid #000;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
  }
  .ReactModal__Content[aria-label="ZoneNameModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="OffensiveModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="looseModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="coach-card-modal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="deffensiveModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="viewsignsModal"] {
    padding: 30px 20px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="pitchingModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="viewModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
  .ReactModal__Content[aria-label="editshufflModal"] {
    padding: 30px 40px !important;
    width: 350px;
  }
}
@media (max-width: 461px) {
  .select_input_download {
    width: 300px;
  }
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 12px;
    margin-left: 0px;
  }
  .list_item_zero {
    font-size: 14px;
  }
  .list_item {
    font-size: 14px;
  }
  .box_2 {
    margin: 3px;
    width: 60px;
    height: 70px;
  }
  .box_3 {
    margin: 3px;
    width: 60px;
    height: 70px;
  }
  .box_4 {
    margin: 3px;
    width: 60px;
    height: 70px;
  }
  .dnld_btn_1 {
    padding-top: 5px;
    width: 300px;
    height: 50px;
  }
}
@media (max-width: 401px) {
  .list_item_zero {
    font-size: 12px;
  }
  .list_item {
    font-size: 12px;
  }
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
    margin-left: 0px;
  }
  label.mrgn {
    margin-top: 24px;
  }
}
@media (max-width: 470px) {
  .box_1 {
    width: 80px;
    height: 85px;
    justify-content: center;
  }
  .zonebox_parent_div_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 390px) {
  .box_1 {
    width: 60px;
    height: 65px;
    justify-content: center;
  }
  .zonebox_parent_div_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .box_4 {
    width: 61px;
    height: 71px;
  }
}
@media (max-width: 370px) {
  .inner_box_1 {
    width: 15px;
    height: 15px;
  }
  .inner_box_2 {
    width: 35px;
    height: 15px;
  }
  .inner_box_row_2 {
    width: 15.5px;
    height: 15px;
  }
  .value {
    font-size: 12px;
  }
  .box_2 {
    margin: 3px;
    width: 50px;
    height: 60px;
  }
  .box_3 {
    margin: 3px;
    width: 50px;
    height: 60px;
  }
  .box_4 {
    margin: 3px;
    width: 50px;
    height: 60px;
  }
}

.three-x .number {
  font-size: 12px;
}
.four-x .number {
  font-size: 8px;
}
.five-x .number {
  font-size: 7px;
}
.six-x .number {
  font-size: 6px;
}

/* .three-x .number {
  font-size: 13px;
}
.four-x .number {
  font-size: 11px;
}
.five-x .number {
  font-size: 6px;
}
.six-x .number {
  font-size: 5px;
} */

/* Progress steps */

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
}
.circle {
  background-color: #fff;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #6ee0de;
  transition: 0.4s ease;
  padding-top: 5px;
}
.circle p {
  font-size: 20px;
  margin-bottom: 0px;
  color: #6ee0de;
}
.progress-line {
  width: 108px;
  height: 4px;
  background: #fff;
  margin-top: 50px;
}
.progress-line.active {
  background: #6ee0de;
}
.circle.active {
  border-color: #fff;
  background: #6ee0de;
}
.circle.active p {
  color: #fff;
}
@media (max-width: 767px) {
  .circle {
    height: 60px;
    width: 60px;
    padding-top: 3px;
  }
  .circle p {
    font-size: 14px;
  }
  .progress-line {
    margin-top: 28px;
  }
  .progress-line {
    width: 90px;
  }
}
@media (min-width: 578px) {
  .span-p {
    display: inline-block;
  }
}
@media (max-width: 577px) {
  .coach-card-main-div {
    flex-direction: column;
  }
  .home_btn button i {
    font-size: 12px !important;
  }
  .home_btn button {
    font-size: 12px !important;
    padding: 6px 15px !important;
  }
  .home_btn {
    position: absolute !important;
    top: 20px !important;
    left: 20px !important;
  }
  .generate-heading-span {
    text-align: center;
    margin: 0px 0px 10px 0px;
  }
  .span-p {
    display: none;
  }
  .circle {
    height: 45px;
    width: 45px;
    padding-top: 3px;
    border: 3px solid #6ee0de;
  }
  .circle p {
    font-size: 18px;
  }
  .progress-line {
    margin-top: 21px;
    width: 40px;
  }
  .float-div-2 p {
    padding-right: 45px;
    padding-left: 50px;
  }
}

.green_color {
  color: rgb(25, 125, 62) !important;
}

.red_color {
  color: #ff0000 !important;
}

.blue_color {
  color: rgb(110, 224, 222) !important;
}

.ReactModal__Content {
  max-height: 95%;
  width: 550px;
}
.p_description_main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0px;
}
.p_description_child {
  flex-basis: 50%;
  text-align: left;
}
@media only screen and (max-width: 576px) {
  .ReactModal__Content {
    max-width: 90%;
  }
  .p_description_child {
    flex-basis: 75%;
  }
}

.home_btn {
  position: absolute;
  top: 40px;
  left: 50px;
}
.home_btn button {
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  border: none;
  outline: none !important;
  background: #6ee0de;
  box-shadow: 5px 5px 4px rgb(0 0 0 / 25%);
  font-style: normal;
  font-size: 14px;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 8px 25px;
}
.home_btn button i {
  padding-right: 8px;
  font-size: 14px;
}

.Modal-Info-desc {
  font-size: 11px;
}

/* scan css */
.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.QRScanner-Container {
  height: 400px;
  width: 400px;
}
@media (max-width: 578px) {
  .QRScanner-Container {
    height: 600px;
    width: 100%;
  }
  .checkbox-checkmark {
    left: 35% !important;
  }
  .input_login_btn {
    font-size: 12px;
  }
  .slect-demo {
    display: block !important;
  }
}

/* checkbox css */

.checkbox-container {
  font-family: "Poppins", sans-serif;
  color: #222;
  font-size: 13px;
  text-align: center;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  border: 1px solid #2c312c;
  position: absolute;
  top: 0;
  left: 42%;
  height: 20px;
  width: 20px;
  background-color: white;
  transform: translateX(-50%);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: white;
  border-color: #6ee0de;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #6ee0de;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkbox-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modalError-text {
  color: rgb(255, 0, 0);
  font-size: 12px;
  text-transform: initial;
}

.resend_code_link {
  text-decoration: underline;
}

/* Table sort Arrows */
th .order .dropup .caret:before {
  margin-left: 1px;
  content: "\2193";
  opacity: 0.6;
}
th .order .dropdown .caret:after {
  content: "\2191";
  opacity: 0.6;
}

th .react-bootstrap-table-sort-order.dropup .caret:before {
  margin-left: 1px;
  content: "\2191";
  opacity: 1;
}

th .react-bootstrap-table-sort-order.dropup .caret:after {
  content: "\2193";
  opacity: 0.6;
}

th .react-bootstrap-table-sort-order .caret:before {
  margin-left: 1px;
  content: "\2191";
  opacity: 0.6;
}

th .react-bootstrap-table-sort-order .caret:after {
  content: "\2193";
  opacity: 1;
}

.zone_name_circle {
  flex-basis: 14%;
  padding: 6px 0;
}

.custom-clr-picker {
  width: 40px !important;
  height: 40px !important;
}
.align-left {
  text-align: left;
}

.circle_incication_border,
.zone_default_border {
  border: 2px solid rgb(154, 153, 155);
}

.pointer {
  cursor: pointer;
}

.react-dropdown-select {
  border: 1px solid #9a999b !important;
  min-height: 47px !important;
  border-radius: 4px !important;
}

.react-dropdown-select-dropdown span,
.react-dropdown-select-content span {
  text-transform: initial !important;
}

.react-dropdown-select:focus,
.react-dropdown-select:focus-within {
  box-shadow: none !important;
}

.ancher_link {
  color: #6ee0de !important;
  float: right;
  font-size: 14px;
  cursor: pointer;
}
.slect-demo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.red_border {
  border-color: red !important;
  position: relative;
}

.red_border::after {
  content: "";
  position: absolute;
  right: 16px;
  width: 2px;
  height: 36px;
  background-color: red;
  transform: rotate(45deg);
}
.border-clr-white {
  border: 1px solid gray;
}
